import { ShortFLowFlowLayout } from '@components/Layouts';
import { ShortAppFlow } from '@containers/short-app';
import { NAMESPACE } from '@models/enums';
import { GetStaticProps } from 'next';
import { useTranslation } from 'next-i18next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import Head from 'next/head';
import { ReactNode } from 'react';

export const getStaticProps: GetStaticProps = async ({ locale }) => ({
  props: {
    ...(await serverSideTranslations(locale || 'en', [
      NAMESPACE.COMMON,
      NAMESPACE.DEFAULT,
      NAMESPACE.LONGAPP,
      NAMESPACE.PROMOTION,
      NAMESPACE.PARTNER,
    ])),
  },
});
const Home = () => {
  const { t } = useTranslation(NAMESPACE.DEFAULT);

  return (
    <>
      <Head>
        <title>{t('FLOW_GET_STARTED')}</title>
      </Head>
      <ShortAppFlow />
    </>
  );
};

Home.getLayout = (home: ReactNode) => <ShortFLowFlowLayout>{home}</ShortFLowFlowLayout>;

export default Home;
